import QRCode from 'qrcode'

const qrcodeGen = async (id, token) => {
  const createURl = `${process.env.REACT_APP_URL}/verify/${id}?token=${token}`
  try {
    const opts = {
      errorCorrectionLevel: 'H',
      type: 'image/jpeg',
      quality: 0.3,
      margin: 2,
    }

    return await QRCode.toDataURL(createURl, opts)
  } catch (err) {
    return err
  }
}

export default qrcodeGen
