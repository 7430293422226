import React from 'react'
import axios from 'axios'
import { isMobile } from 'react-device-detect'
import { useNavigate, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'

import { InputField, Loader, SelectField } from '..'

const validationSchema = Yup.object({
  address: Yup.string().required('Street Address is required'),
  zip: Yup.string().required('Postal/Zip Code is required'),
  city: Yup.string().required('City is required'),
  country: Yup.string().required('Country is required'),
})

const KycVerifyInfo = () => {
  const [loading, setLoading] = React.useState(true)
  const [kycData, setKycData] = React.useState({})

  const navigate = useNavigate()
  const { id } = useParams()

  React.useEffect(() => {
    if (id) {
      checkVerifyReq(id)
    } else {
      setLoading(false)
      navigate('/not-valid')
    }
    // eslint-disable-next-line
  }, [id, navigate])

  const checkVerifyReq = async (id) => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/kyc-request/${id}`
      )
      if (data.success === true) {
        setKycData(data.data)
        const infoData = await axios.get(
          `${process.env.REACT_APP_API_URL}/info/${id}`
        )
        if (infoData.data.success === true) {
          navigate(`/${id}/start?token=${infoData.data.token}`)
        } else {
          setLoading(false)
        }
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
      navigate('/not-valid')
    }
  }

  const formik = useFormik({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      name: kycData.name || '',
      email: kycData.email || '',
      address: kycData.address || '',
      zip: kycData.zip || '',
      city: kycData.city || '',
      country: kycData.country || 'CA',
    },
    onSubmit: async (values) => {
      const reqBody = { ...values, requestId: kycData._id }
      try {
        const { data } = await axios.post(
          `${process.env.REACT_APP_API_URL}/info`,
          reqBody
        )
        if (data.success === true) {
          formik.setSubmitting(false)
          if (isMobile) {
            navigate(`/verify/${id}?token=${data.token}`)
          } else {
            navigate(`/${id}/start?token=${data.token}`)
          }
        }
      } catch (err) {
        console.log(err)
      }
    },
  })

  if (loading) return <Loader />

  return (
    <div className='kycCard'>
      <div className='kycCardHeader'>
        <h2>Verify Your Identity 1/3</h2>
        <h3>Please fill out your current home address.</h3>
      </div>

      <div className='kycCardBody'>
        <form method='post' className='kycForm' onSubmit={formik.handleSubmit}>
          <InputField label='Full Name' name='name' disabled formik={formik} />
          <InputField
            label='Email Address'
            name='email'
            disabled
            formik={formik}
          />
          <InputField
            name='address'
            label='Steet Address'
            placeholder='Street Address'
            formik={formik}
          />
          <InputField
            name='zip'
            label='Postal/Zip Code'
            placeholder='Postal/Zip Code'
            formik={formik}
          />
          <InputField
            name='city'
            label='City'
            placeholder='City'
            formik={formik}
          />
          <SelectField name='country' label='country' formik={formik} />
          <button
            className='kycButton'
            type='submit'
            disabled={!formik.isValid || formik.isSubmitting}
          >
            Continue
          </button>
        </form>
      </div>
    </div>
  )
}

export default KycVerifyInfo
