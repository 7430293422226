import React from 'react'
import CameraPhoto, { FACING_MODES } from 'jslib-html5-camera-photo'

const Camera = () => {
  const [camera, setCamera] = React.useState(null)
  const [camMode, setCamMode] = React.useState('FACING_MODES.ENVIRONMENT')
  const [camConfig, setCamConfig] = React.useState({
    aspectRatio: 0,
    frameRate: 0,
    height: 0,
    width: 0,
  })
  const [photo, setPhoto] = React.useState('')
  const [enableCam, setEnableCam] = React.useState(false)

  const videoRef = React.useRef(null)

  // Init Camera
  React.useEffect(() => {
    setCamera(new CameraPhoto(videoRef.current))
  }, [])

  const startCamera = async (idealFacingMode, idealResolution) => {
    setPhoto('')
    const _camConfig = camera.getCameraSettings()
    if (_camConfig) {
      const { aspectRatio, frameRate, height, width } = _camConfig
      setCamConfig({ aspectRatio, frameRate, height, width })
      setEnableCam(true)
    }
    try {
      await camera.startCamera(idealFacingMode, idealResolution)
    } catch (err) {
      console.error(err)
    }
  }

  const cameraSwitch = async () => {
    if (camMode === FACING_MODES.ENVIRONMENT) {
      setCamMode(FACING_MODES.USER)
    } else {
      setCamMode(FACING_MODES.ENVIRONMENT)
    }
    startCamera()
  }

  const stopCamera = async () => {
    try {
      await camera.stopCamera()
    } catch (err) {
      console.error(err)
    }
  }

  const takePhoto = async () => {
    setPhoto('')
    const _photo = await camera.getDataUri({ sizeFactor: 1 })
    setPhoto(_photo)
  }

  console.log(camConfig, enableCam)

  return (
    <div>
      <button
        onClick={() => {
          let facingMode = FACING_MODES.ENVIRONMENT
          let idealResolution = {
            width: camConfig.width,
            height: camConfig.height,
          }
          startCamera(facingMode, idealResolution.height > 0 && idealResolution)
        }}
      >
        Start Cemera
      </button>
      <br />
      <button
        onClick={() => {
          stopCamera()
        }}
      >
        Stop
      </button>
      <br />
      <button
        onClick={() => {
          takePhoto()
        }}
      >
        Take photo
      </button>
      <br />
      <button
        onClick={() => {
          cameraSwitch()
        }}
      >
        Switch camera
      </button>
      <br />

      <video ref={videoRef} autoPlay playsInline />
      <img alt='imgCamera' src={photo} />
    </div>
  )
}

export default Camera
