import React from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { FcInfo } from 'react-icons/fc'
import { AiOutlineQrcode } from 'react-icons/ai'
import { GiReturnArrow } from 'react-icons/gi'
import { ImMobile2 } from 'react-icons/im'

import { qrcodeGen } from '../../utils'
import { ClickToCopy, Loader } from '..'

const KycVerifyStart = () => {
  const [loading, setLoading] = React.useState(true)
  const [token, setToken] = React.useState('')
  const [qrCode, setQrCode] = React.useState('')

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { id } = useParams()

  React.useEffect(() => {
    if (searchParams.get('token')) {
      if (isMobile) {
        navigate(`/verify/${id}?token=${searchParams.get('token')}`)
      }
      setToken(searchParams.get('token'))
      async function createQrCode() {
        const _qrCode = await qrcodeGen(id, searchParams.get('token'))
        setQrCode(_qrCode)
      }
      createQrCode()
      setLoading(false)
    } else {
      !loading && navigate('/not-valid')
    }
  }, [searchParams, loading, id, navigate])

  if (loading) return <Loader />

  return (
    <div className='kycCard'>
      <div className='kycCardHeader'>
        <h2>Verify Your Identity 2/3</h2>
        <h3>We need to verify one of your documents as take a selfie</h3>
      </div>

      <div className='kycCardBody'>
        <ul className='kycSteps'>
          <li>
            <div className='kycStepIcon'>
              <AiOutlineQrcode color='#000' size={24} />
            </div>
            <div className='kycStepText'>
              <h4>Scan the QR code with your camera</h4>
              <p>
                By scanning the QR code, you will be able to do your
                verification steps on your mobile phone.
              </p>
            </div>
          </li>
        </ul>
        <div className='kycQrWrap'>
          {qrCode && <img className='qrImg' src={qrCode} alt='qr' />}
          <ClickToCopy
            text={`${process.env.REACT_APP_URL}/verify/${id}?token=${token}`}
          />
        </div>
        <ul className='kycSteps'>
          <li>
            <div className='kycStepIcon'>
              <ImMobile2 color='#000' size={20} />
            </div>
            <div className='kycStepText'>
              <h4>Complete verification on your mobile</h4>
              <p>
                Take a selfie and picture of one of your documents on your
                phone.
              </p>
            </div>
          </li>
          <li>
            <div className='kycStepIcon'>
              <GiReturnArrow color='#000' size={20} />
            </div>
            <div className='kycStepText'>
              <h4>Return here to continue</h4>
              <p>
                Once you are done with your verification steps you will continue
                on this device.
              </p>
            </div>
          </li>
        </ul>
        <div className='kycStepBottom'>
          <div className='kycStepBottomIcon'>
            <FcInfo color='#000' size={20} />
          </div>
          <div
            className='kycStepBottomText'
            onClick={() => navigate(`/verify/${id}?token=${token}`)}
          >
            <p>Don't have a smartphone or QR code reader?</p>
            <h4>
              <u>Continue verification on this device</u>
            </h4>
          </div>
        </div>
      </div>
    </div>
  )
}

export default KycVerifyStart
