import React from 'react'
import axios from 'axios'
import { FcInfo } from 'react-icons/fc'
import { BsGlobe } from 'react-icons/bs'
import { AiOutlineCar } from 'react-icons/ai'
import { HiOutlineIdentification } from 'react-icons/hi'

import KycUploadDoc from './KycUploadDoc'
import KycUploadStep from './KycUploadStep'

const KycVerifyDocuments = ({ id, type, token, handleNext }) => {
  const [country, setCountry] = React.useState('CA')
  const [front, setFront] = React.useState('')
  const [back, setBack] = React.useState('')
  const [continued, setContinued] = React.useState(false)
  const [kycType, setKycType] = React.useState('')
  const [loading, setLoading] = React.useState(false)

  React.useEffect(() => {
    if (front && back) {
      // Next Step
      handleNext([front, back])
    }

    // eslint-disable-next-line
  }, [front, back])

  const uploadToCloud = async (file) => {
    const formData = new FormData()
    formData.append('file', file)

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload`,
        formData,
        config
      )
      if (data.success === true) {
        setLoading(false)
        return data.data
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  const handleSubmit = async (photo) => {
    setLoading(true)

    if (!front) {
      setFront(photo)
      const _uploaded = await uploadToCloud(photo)
      setFront(_uploaded)
      setContinued(false)
    } else {
      setBack(photo)
      const _uploaded = await uploadToCloud(photo)
      setBack(_uploaded)
    }
  }

  console.log(front, back)

  return (
    <>
      {kycType === '' ? (
        <div className='kycCardBody kycForm'>
          <div className='formRow'>
            <label htmlFor='country'>Choose Issuing Country/Region</label>
            <select
              name='country'
              id='country'
              onChange={(e) => setCountry(e.target.value)}
              onBlur={(e) => setCountry(e.target.value)}
              value={country}
            >
              <option value='US'>United States</option>
              <option value='CA'>Canada</option>
            </select>
          </div>
          <h2>Select ID type</h2>
          <p>Use a valid government-issued photo ID</p>
          <div className='kycIdType'>
            <button onClick={() => setKycType('Passport')}>
              <BsGlobe color='#2b2b2b' size={20} />
              <span>Passport</span>
            </button>
            <button onClick={() => setKycType("Driver's License")}>
              <AiOutlineCar color='#2b2b2b' size={20} />
              <span>Driver's License</span>
            </button>
            <button onClick={() => setKycType('Identity Card')}>
              <HiOutlineIdentification color='#2b2b2b' size={20} />
              <span>Identity Card</span>
            </button>
          </div>
          <div className='kycVerifyInfo'>
            <FcInfo color='#000' size={20} />
            <p>Have you checked if your ID is supported?</p>
          </div>
        </div>
      ) : (
        <>
          {kycType && !continued && !front && (
            <KycUploadStep
              type={kycType}
              title={`Front of ${kycType}`}
              desc={`Take a photo of the front of your ${kycType}.`}
              image='/images/card-front.svg'
              onClick={() => setContinued(true)}
            />
          )}
          {kycType && !continued && front && (
            <KycUploadStep
              type={kycType}
              title={`Back of ${kycType}`}
              image='/images/card-back.svg'
              onClick={() => setContinued(true)}
            />
          )}
          {kycType && continued && (
            <KycUploadDoc
              title='Document Verification'
              helpText='Please center your ID in the frame below and click to take a photo.'
              kycType={kycType}
              setKycType={setKycType}
              setContinued={setContinued}
              requestId={id}
              token={token}
              loading={loading}
              type={type}
              handleSubmit={handleSubmit}
            />
          )}
        </>
      )}
    </>
  )
}

export default KycVerifyDocuments
