import React from 'react'
import { AiOutlineLeft } from 'react-icons/ai'

const KycBack = ({ title, onClick }) => {
  return (
    <div className='kycBack' onClick={onClick}>
      <AiOutlineLeft size={25} color='#000' />
      <h4>{title}</h4>
    </div>
  )
}

export default KycBack
