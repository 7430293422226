const SelectField = ({ name, label, formik, ...restProps }) => {
  const { handleChange, handleBlur, touched, errors } = formik

  return (
    <div
      className={`formRow ${touched[name] && errors[name] ? 'has-error' : ''}`}
    >
      <label htmlFor={name}>{label}</label>
      <select
        name={name}
        id={name}
        onChange={handleChange}
        onBlur={handleBlur}
        value={formik.values[name]}
        {...restProps}
      >
        <option value='US'>United States</option>
        <option value='CA'>Canada</option>
      </select>
      <span className='inputError'>
        {touched[name] && errors[name] ? errors[name] : null}
      </span>
    </div>
  )
}

export default SelectField
