import React from 'react'
import { isMobile } from 'react-device-detect'
import { useNavigate } from 'react-router-dom'
import CameraPhoto, { FACING_MODES } from 'jslib-html5-camera-photo'
import {
  AiOutlineCamera,
  AiOutlineClose,
  AiOutlineCloudUpload,
  AiOutlineLeft,
  AiOutlineMobile,
  AiOutlineUpload,
} from 'react-icons/ai'
import { RiCameraSwitchLine } from 'react-icons/ri'

import { BeatLoader } from 'react-spinners'
import { dataURLtoFile } from '../../utils'

const KycUpload = ({
  title,
  desc,
  helpText,
  loading,
  type,
  requestId,
  token,
  handleSubmit,
}) => {
  const [camera, setCamera] = React.useState(null)
  const [camMode, setCamMode] = React.useState(FACING_MODES.ENVIRONMENT)
  const [photo, setPhoto] = React.useState('')
  const [previewPhoto, setPreviewPhoto] = React.useState('')
  const [enableCam, setEnableCam] = React.useState(false)

  const kycCameraRef = React.useRef(null)
  const videoRef = React.useRef(null)
  const fileInput = React.useRef(null)

  const navigate = useNavigate()

  // Init Camera
  React.useEffect(() => {
    if (videoRef.current) {
      setCamera(new CameraPhoto(videoRef.current))
    }
  }, [])

  // Preview Photo
  React.useEffect(() => {
    if (photo) {
      const reader = new FileReader()
      reader.onload = (e) => {
        setPreviewPhoto(e.target.result)
      }
      reader.readAsDataURL(photo)
    }
  }, [photo])

  React.useEffect(() => {
    if (camera && isMobile) {
      setEnableCam(true)
      startCamera()
    }
    // eslint-disable-next-line
  }, [camera, isMobile])

  // Listen for file selection for fileInput and get photo
  React.useEffect(() => {
    if (fileInput.current) {
      fileInput.current.addEventListener('change', () => {
        const file = fileInput.current.files[0]
        setPhoto(file)
      })
    }
  }, [])

  const startCamera = async () => {
    setPreviewPhoto('')
    try {
      await camera.startCamera(camMode)
      setEnableCam(true)
    } catch (err) {
      console.error(err)
    }
  }

  const stopCamera = async () => {
    try {
      await camera.stopCamera()
    } catch (err) {
      console.error(err)
    }
  }

  const cameraSwitch = async () => {
    if (camMode === FACING_MODES.ENVIRONMENT) {
      setCamMode(FACING_MODES.USER)
    } else {
      setCamMode(FACING_MODES.ENVIRONMENT)
    }
    startCamera()
  }

  const takePhoto = async () => {
    setPreviewPhoto('')
    const dataURL = await camera.getDataUri({ sizeFactor: 1 })
    const convertedFile = dataURLtoFile(dataURL, `${type}-${Date.now()}.jpg`)

    console.log(convertedFile)
    setPhoto(convertedFile)
  }

  return (
    <div
      className={`kycCardBody kycUpload ${enableCam ? 'has-camera' : ''} ${
        isMobile ? 'isMobile' : ''
      }`}
    >
      {!enableCam && (
        <div className='kycCardHeader'>
          <h2>{title}</h2>
          <h3>{desc}</h3>
        </div>
      )}
      <div className='kycIdType'>
        {!isMobile && (
          <button
            onClick={() => navigate(`/${requestId}/start?token=${token}`)}
          >
            <AiOutlineMobile color='#2b2b2b' size={24} />
            <span>Continue on mobile</span>
          </button>
        )}
        <button onClick={startCamera}>
          <AiOutlineCamera color='#2b2b2b' size={20} />
          <span>{isMobile ? 'Take a photo' : 'Take photo using webcam'}</span>
        </button>
        <button onClick={() => fileInput.current.click()}>
          <AiOutlineCloudUpload color='#2b2b2b' size={20} />
          <span>Upload file from this device</span>
          <input
            ref={fileInput}
            type='file'
            hidden
            accept='image/*,application/pdf,application/vnd.ms-excel'
          />
        </button>
      </div>
      {!enableCam &&
        fileInput.current &&
        fileInput.current.value &&
        previewPhoto && (
          <div className='kycPhotoPreview'>
            <img src={previewPhoto} alt='' />
          </div>
        )}
      <div className={`kycCameraWrap ${type ? type : ''}`}>
        <div className='cameraInner'>
          <div className='cameraActionsTop'>
            {enableCam && (
              <div className='kycBack' onClick={() => setEnableCam(false)}>
                <AiOutlineLeft size={25} color='#fff' />
                <h4>{title}</h4>
              </div>
            )}
            <button
              title='Remove Photo'
              className='kycCameraReset'
              disabled={!previewPhoto.length}
              onClick={() => {
                setPhoto('')
                setPreviewPhoto('')
              }}
            >
              <AiOutlineClose color='#ff0041' size={25} />
            </button>
          </div>
          <div
            className={previewPhoto ? 'kycCamera has-photo' : 'kycCamera'}
            ref={kycCameraRef}
          >
            {previewPhoto && (
              <img className='kycCapturedImg' src={previewPhoto} alt='' />
            )}
            <video className='kycVideo' ref={videoRef} autoPlay playsInline />
          </div>
          <div className='cameraActionsBottom'>
            <button
              title='Upload a photo'
              className='kycCameraUpload'
              onClick={() => {
                fileInput.current.click()
              }}
            >
              <AiOutlineUpload color='#2b2b2b' size={25} />
            </button>
            <button
              title='Take a photo'
              className='kycCameraBtn'
              onClick={takePhoto}
              disabled={previewPhoto.length}
            >
              <AiOutlineCamera color='#2b2b2b' size={30} />
            </button>
            <button
              title='Switch camera'
              className='kycCameraSwitch'
              onClick={cameraSwitch}
              disabled={!isMobile}
            >
              <RiCameraSwitchLine color='#2b2b2b' size={25} />
            </button>
          </div>
        </div>
        <p className='kycHelpText'>{helpText}</p>
      </div>
      {photo && (
        <>
          <button
            className='kycButton'
            disabled={loading}
            onClick={() => {
              handleSubmit(photo)
              camera && camera.stream && stopCamera()
            }}
          >
            Continue {loading && <BeatLoader size={5} color='#fff' />}
          </button>
        </>
      )}
    </div>
  )
}

export default KycUpload
