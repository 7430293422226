import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { BsCheckCircle } from 'react-icons/bs'
import { Loader } from '../components'

const Success = () => {
  const [loading, setLoading] = React.useState(true)

  const navigate = useNavigate()
  const { id } = useParams()

  React.useEffect(() => {
    if (id) {
      checkVerifyReq(id)
    } else {
      setLoading(false)
      navigate('/not-valid')
    }

    // eslint-disable-next-line
  }, [id, navigate])

  const checkVerifyReq = async (id) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/kyc-request/${id}`
    )
    if (data.success === true) {
      setLoading(false)
    } else {
      setLoading(false)
      navigate('/not-valid')
    }
  }

  if (loading) return <Loader />

  return (
    <div className='successWrap'>
      <div className='successInner'>
        <BsCheckCircle size={75} color='#4eae49' />
        <h1>Thank you!</h1>
        <h4>Your request has been submitted and is being processed.</h4>
      </div>
    </div>
  )
}

export default Success
