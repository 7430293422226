import React from 'react'
import { AiFillCopy, AiOutlineCopy } from 'react-icons/ai'

const ClickToCopy = ({ text }) => {
  const [copied, setCopied] = React.useState(false)

  const copyToClipboard = () => {
    navigator.clipboard.writeText(text)
    setCopied(true)
  }

  return (
    <button
      className={copied ? 'ClickToCopy copied' : 'ClickToCopy'}
      onClick={copyToClipboard}
    >
      {copied ? (
        <AiFillCopy color='#1acc6c' size={24} />
      ) : (
        <AiOutlineCopy color='#000' size={24} />
      )}
      <h5>
        {!copied ? 'Copy Link to Verification' : 'Link Copied to Clipboard'}
      </h5>
    </button>
  )
}

export default ClickToCopy
