import React from 'react'
import axios from 'axios'

import KycUpload from './KycUpload'

const KycVerifyPhotoId = ({ id, type, token, handleNext }) => {
  const [loading, setLoading] = React.useState(false)

  const handleSubmit = async (photo) => {
    setLoading(true)

    const formData = new FormData()
    formData.append('file', photo)

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        contentType: 'multipart/form-data',
      },
    }

    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/upload`,
        formData,
        config
      )
      if (data.success === true) {
        // Next Step
        await handleNext([data.data])
      }
    } catch (error) {
      console.log(error)
      setLoading(false)
    }
  }

  return (
    <KycUpload
      title='ID Verification'
      desc='We need to verify one of your documents'
      helpText='Please center your ID in the frame below and click to take a photo.'
      requestId={id}
      token={token}
      loading={loading}
      type={type}
      handleSubmit={handleSubmit}
    />
  )
}

export default KycVerifyPhotoId
