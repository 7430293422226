import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { KycVerifyInfo, KycVerifyStart } from '../components/kyc'

const Home = () => {
  return (
    <div className='kycWrap'>
      <div className='kycInner'>
        <Routes>
          <Route path='/' element={<KycVerifyInfo />} />
          <Route path='/start/*' element={<KycVerifyStart />} />
        </Routes>
      </div>
    </div>
  )
}

export default Home
