import React from 'react'
import { ScaleLoader } from 'react-spinners'

const Loader = () => {
  return (
    <div className='loaderWrap'>
      <div>
        <ScaleLoader color='#1d4287' />
      </div>
    </div>
  )
}

export default Loader
