import React from 'react'
import axios from 'axios'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  KycVerifyDocuments,
  KycVerifyPhotoId,
  KycVerifySelfie,
} from '../components/kyc'
import { Loader } from '../components'

const Verify = () => {
  const [loading, setLoading] = React.useState(true)
  const [kycData, setKycData] = React.useState({})
  const [token, setToken] = React.useState('')
  const [steps, setSteps] = React.useState([])

  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const { id } = useParams()

  React.useEffect(() => {
    if (searchParams.get('token')) {
      setToken(searchParams.get('token'))
    } else {
      navigate('/not-valid')
    }
  }, [searchParams, navigate])

  React.useEffect(() => {
    if (id && token) {
      checkVerifyReq(id)
    }

    // eslint-disable-next-line
  }, [id, token])

  const checkVerifyReq = async (id) => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/status/${id}`,
        config
      )
      console.log(data)
      if (data.success === true && data.data.reqId === id) {
        setKycData(data.data)
        const _steps = data.data.steps.filter((item) => !item.completed)
        if (_steps.length === 0) {
          navigate(`/verify/${id}/success`)
        }
        setSteps(_steps)
        setLoading(false)
      }
    } catch (err) {
      console.error(err)
      setLoading(false)
      navigate('/not-valid')
    }
  }

  const handleNext = async (images) => {
    if (steps.length >= 1) {
      const newSteps = steps.map((item) => {
        if (item.step === steps[0].step) {
          return { ...item, completed: true, images }
        }
        return item
      })
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
        await axios.put(
          `${process.env.REACT_APP_API_URL}/status/${id}`,
          { steps: newSteps },
          config
        )

        checkVerifyReq(id)
        return true
      } catch (err) {
        console.error(err)
      }
    }
  }

  if (loading) return <Loader />

  return (
    <div className='kycWrap'>
      <div className='kycInner'>
        {steps && steps.length > 0 && steps[0].step === 'requirePhotoId' && (
          <KycVerifyPhotoId
            id={id}
            kycData={kycData}
            token={token}
            type='id'
            handleNext={handleNext}
          />
        )}
        {steps && steps.length > 0 && steps[0].step === 'requireSelfie' && (
          <KycVerifySelfie
            id={id}
            kycData={kycData}
            token={token}
            type='selfie'
            handleNext={handleNext}
          />
        )}
        {steps && steps.length > 0 && steps[0].step === 'requireDocument' && (
          <KycVerifyDocuments
            id={id}
            kycData={kycData}
            token={token}
            type='id document'
            handleNext={handleNext}
          />
        )}
      </div>
    </div>
  )
}

export default Verify
