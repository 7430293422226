import React from 'react'

const KycUploadStep = ({ type, title, desc, image, onClick }) => {
  return (
    <div className='kycStepWrap'>
      <div className='kycStepInner'>
        <div className='kycStepTitle'>
          <h4>{title}</h4>
          <p>{desc}</p>
        </div>
        <div className='kycStepBody'>
          <img src={image} alt='' />
        </div>
        <div className='kycStepFooter'>
          <button className='kycButton' onClick={onClick}>
            Continue
          </button>
        </div>
      </div>
    </div>
  )
}

export default KycUploadStep
