import React from 'react'
import { MdOutlineCancel } from 'react-icons/md'

const NotFound = () => {
  return (
    <div className='successWrap errorWrap'>
      <div className='successInner'>
        <MdOutlineCancel size={75} color='#f16867' />
        <h1>Something wrong!</h1>
        <h4>
          Sorry, something went wrong or we can't find the page you're looking
          for.
        </h4>
      </div>
    </div>
  )
}

export default NotFound
