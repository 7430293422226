import { Routes, Route } from 'react-router-dom'
import { Home, NotFound, Success, Verify } from './pages'
import Camera from './pages/Camera'

import './App.css'

const App = () => {
  return (
    <>
      <Routes>
        <Route path='camera' element={<Camera />} />
        <Route path=':id/*' element={<Home />} />
        <Route path='/verify/:id/success' element={<Success />} />
        <Route path='/verify/:id/*' element={<Verify />} />
        <Route path='/not-valid' element={<NotFound />} />
        <Route path='*' element={<NotFound />} />
      </Routes>
    </>
  )
}

export default App
